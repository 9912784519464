//main: layout.less

.element-latest, .element-highlight, .element-bestseller, .element-promotion, .element-banner-shop, .element-tags-vitrine, .element-newsletter-vitrine {
    display: inline-block!important;
}

#vitrine {
	.element-highlight {
		.title;
	}

	.element-bestseller {
		.title;
	}

	.element-content {
		.header-box-content {
			position: relative;
			padding: 10px 0 30px;
			width: 100%;

			.title-primary, .title-secundary, h1, h2, h3, h4, h5, h6 {
				display: block;
				position: relative;
				text-transform: uppercase;
				text-align: center;
				width: 100%;
				display: block;
				line-height: 45px;
				font-size: 30px;
				.font-medium;

				> span {
				}

				&::before {
					transform: translate(-50%, 0);
					content: "";
					width: 40px;
					height: 40px;
					display: block;
					bottom: 15px;
					z-index: -1;
					left: 50%;
					position: absolute;
					background: @Amaranth;
					.border-radius(50px);
				}

				&:after {
					content: " + ";
					line-height: 61px;
					display: block;
					font-size: 33px;
					color: @white;
					.font-medium;
				}
			}

			&::before {
				transform: translate(-50%, 0);
				background: @Amaranth;
				position: absolute;
				content: "";
				width: 40%;
				height: 5px;
				display: block;
				bottom: 62px;
				z-index: -1;
				left: 50%;
			}
		}
	}
}


#product-list {
	padding: 20px 0;

	.product-list-item {
		height: auto!important;
		border-bottom: 4px solid @Amaranth;
		position: relative;
		display: block;

		.product-list-item-inner {
			.product-image {
				position: relative;
				display: block;

				a:not(.label-unavailable) {
					padding-top: 150%;

					img {
						min-width: 100%;
					}
				}

				div[style="display:none"]+.label-launch {
					margin: -55px 0 0 0;
				}

				.label-product {
					text-transform: uppercase;
					background: @Amaranth;
					position: absolute;
					line-height: 50px;
					font-size: 12px;
					color: @white;
					height: 50px;
					width: 50px;
					left: 5px;
					top: 5px;
					.border-radius(50px);
					.font-bold;

					&.label-launch {
						visibility: hidden;
						line-height: 0;
						font-size: 0;
						top: 5px;

						&::before {
							content: "Novo";
							visibility: visible;
							background: @Blue;
							line-height: 50px;
							font-size: 12px;
							display: block;
							height: 50px;
							width: 50px;
						}
					}

					&.label-promo {
						+.label-launch {
							top: 60px;
						}
					}

					&.label-freeshiping {
						display: none;
					}
				}
			}

			.product-info {
				.product-name {
					margin-right: 5%;
					letter-spacing: 1px;
					line-height: 20px;
					text-align: left;
					min-height: 80px;
					max-height: 80px;
					font-size: 18px;
					float: left;
					width: 60%;
					.font-light;

					a {
						&:hover {
							text-decoration: none;
						}
					}
				}

				.product-rating  {
					display: none;
				}

				> .product-price, .product-old-price {
					text-align: right;
					font-size: 22px;
					float: left;
					width: 35%;
					.font-bold;

					>span {
						&.product-big, &.product-big-price {
							font-size: 20px;
						}

						&:first-child {
							display:  none;
						}
					}

					.product-strikethrough-price {
						font-size: 16px;
					}
				}

				.product-parcelled-price {
					visibility: hidden;
					text-align: right;
					display: block;
					line-height: 0;
					font-size: 0;

					.product-number-parcels, .product-big-price {
						visibility: visible;
						text-align: right;
						line-height: 16px;
						font-size: 12px;
						.font-light;
					}

					.product-number-parcels {
						.fas;

						&::before {
							content: "\f09d";
							line-height: 16px;
							margin-right: 6px;
							font-size: 10px;
						}

						&::after {
							content: " de ";
						}
					}
				}

				.product-sell-price {
					position: relative;

					.product-price {
						line-height: 24px;
						width: 100%;
						font-size: 20px;
						text-align: center;
						position: relative;

						ins {
							line-height: 24px;
							width: 100%;
							font-size: 20px;
							text-align: center;
							position: relative;
						}
					}
				}
			}

			.wrapper-btn-product, .wrapper-product-combination {
				display: none;
			}
		}

		&:hover {
			.product-list-item-inner {
				.product-image {
					&::before {
						content: "";
						display: block;
						position: absolute;
						background: @black;
						opacity: .2;
						height: auto;
						width: auto;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						z-index: 4;
					}

					&::after {
						content: "";
						display: block;
						position: absolute;
						border: 2px solid @white;
						height: auto;
						width: auto;
						top: 20px;
						left: 20px;
						right: 20px;
						bottom: 20px;
						z-index: 5;
					}

					.label-product {
						display: none!important;
					}
				}

				.product-info {
					.product-rating  {
						position: absolute;
						display: block;
						bottom: 105px;
						width: 100%;
						left: 20px;
						right: 20px;

						.product-rating-stars-wrapper {
							position: relative;
							background: @white;
							z-index: 5;
							width: 100%;
							line-height: 30px;
							height: 30px;

							.product-rating-stars-bg {
								width: 84px!important;
								position: relative;
								display: block;
								margin: 5px auto;

								.product-rating-stars {
									margin: 0px auto;
								}
							}
						}

						.product-rating-number {
							display: none;
						}
					}
				}

				.wrapper-btn-product {
					z-index: 99;
					display: block;
					position: absolute;
					transform: translate(-50%, 0);
					top: 35%;
					left: 50%;
					margin: 0 auto;

					a {
						background: @Amaranth;
						padding: 0;
						margin: 0;
						height: 60px;
						width: 60px;
						.border-radius(50px);

						.icon-buy {
							display: block;
							width: 50px;
							height: 40px;
							padding: 10px 5px;
							margin: 0;

							&::after {
								content: "";
								background: url("@{bgs}") no-repeat;
								background-position: 0 -50px;
								display: block;
								margin: 10px auto;
								width: 50px;
								height: 40px;
								padding: 0;
								margin: 0;
							}
						}

						.btn-text {
							display: none;
						}
					}
				}
			}
		}
	}
}

.title {
	.header-box-content {
		position: relative;
		padding: 10px 0 30px;
		width: 100%;

		.title-primary, .title-secundary, h1, h2, h3, h4, h5, h6 {
			display: block;
			position: relative;
			text-transform: uppercase;
			text-align: center;
			line-height: 60px;
			font-size: 18px;
			width: 100%;
			.font-light;

			> span {
				display: block;
				line-height: 65px;
				font-size: 60px;
				.font-medium;

				&::before {
					transform: translate(-50%, 0);
					background: @AnotherAmarath;
					position: absolute;
					content: "";
					width: 40%;
					height: 5px;
					display: block;
					bottom: 31px;
					z-index: -1;
					left: 50%;
				}
			}

			&::before {
				transform: translate(-50%, 0);
				content: "";
				width: 40px;
				height: 40px;
				display: block;
				bottom: 14px;
				z-index: -1;
				left: 50%;
				position: absolute;
				background: @AnotherAmarath;
				.border-radius(50px);
			}

			&:after {
				content: " + ";
				line-height: 61px;
				font-size: 33px;
				color: @white;
				.font-medium;
			}
		}
	}
}

//main: layout.less

.element-banner-shop {
	width: 100%;

	.container-12 {
		margin-left: 0;
		margin-right: 0;
		max-width: 100%;
		min-width: 100%;
		width: 100%;

		.grid-12 {
			padding-left: 0px;
			padding-right: 0px;
			width: 100%;
		}
	}
}

.pagina-home {
	#nav-main {
		//Banner Stripe
		.element-banner-stripe {
			background: transparent;
			margin-top: -55px;
			padding: 0;
		}
	}
}

#nav-main {
	//Banner Principal
	.element-banner-main {
		margin-bottom: 0px!important;

		> .container-12, .container-12 .grid-12 {
			max-width: 100%;
			min-width: 100%;
			padding-left: 0;
			padding-right: 0;
			width: 100%;

			.bx-wrapper {
				.bx-viewport {
					height: auto !important;
					position: relative;
					width: 100%;
				}
			}
		}
	}

	//Banner Stripe
	.element-banner-stripe {
		background: @MineShaft;
		margin-top: 0;
		padding: 0;

		#banner-stripe {
			min-height: 110px;
			background: @MineShaft;
			color: @white;
			display: flex;
			justify-content: space-between;
			text-align: center;
			width: auto;
			padding: 0px 30px;
			height: 60px;

			>li {
				margin: auto;
				position: relative;
				text-transform: uppercase;
			}

			li {
				height: 60px;
				padding: 0 30px;

				img, p {
					padding: 5px 15px;
					display: inline-block;
					margin: 0 auto;
					float: left;
				}

				img {
					border: 2px solid @Amaranth;
					max-width: 50px;
				}

				p {
					line-height: 25px;
					display: inline-block;
					text-align: left;
					font-size: 14px;
					.font-light;

					span {
						text-align: left;
						line-height: 10px;
						font-size: 10px;
						color: @gray;
						.font-light;
					}
				}
			}
		}
	}
}

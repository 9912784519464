//main: layout.less

@text-color: #000;

body {
	color: @text-color;
	.font-regular;
	font-size: 14px;
	overflow-x: hidden;
}

* {
	&:before, &:after {
		vertical-align: top;
	}
}

img {
	max-width: 100%;
}

//main: layout.less

.element-search {
	display: block!important;
	margin: 22px 0px;

	#form-search-header {
		.search-inner {
			input {
				height: 56px;
				width: 100%;
				min-width: 300px;
				color: @white;
				border: 2px solid @gray;
				background: transparent;
				padding: 15px 60px 15px 10px;
				.placeholder(@white);
			}

			button {
				background: transparent;
				position: absolute;
				padding: 15px;
				bottom: 0;
				right: 0;
				top: 0;

				.icon-search {
					display: none;
				}

				.btn-text {
					line-height: 0;
					font-size: 0;
					visibility: visible;

					&:after {
						content: "ok";
						text-transform: uppercase;
						line-height: 14px;
						font-size: 14px;
						color: @AnotherAmarath;
						.font-regular;
					}
				}
			}

			.wrapper-advanced-search {
				.advanced-search {
					background: @CodGray;
					border: 2px solid @gray;

					.advanced-search-categories, .advanced-search-list {
						.advanced-search-category-item, .advanced-search-item {
							&:hover {
								a {
									background: @gray;
									color: @black;
								}
							}

							.product-image {
								width: 50px;
								height: 50px;

								img {
									width: auto;
									height: auto;
									background: @white;
								}
							}
						}
					}

					.advanced-search-header {
						.advanced-search-title {
							text-transform: uppercase;
							color: @AnotherAmarath;
							.font-bold;
						}
					}
				}
			}
		}
	}
}

//main: layout.less

.list-products-shopping-cart {
	background: @white;
	border: 1px solid transparent!important;
	color: @MineShaft;
	text-transform: initial;
	.box-shadow(0px 5px 20px 0px rgba(0,0,0,0.4));
	.font-regular;
}

#one-step-checkout-wrapper {
	.bx-viewport {
		padding-top: 80px;
	}
}

#one-step-checkout-main {
	> li {
		background: @white;
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 30px;
		.box-sizing;

		.header-box-content {
			left: 0;
			position: absolute;
			right: 0;
			top: -80px;
		}

		.list-item-wrapper-center {
			ul {
				&.list-item {
					li {
						.fas;

						&::before {
							content: "\f00c";
							display: inline-block;
							color: @MineShaft;
							margin-right: 5px;
						}
					}
				}
			}
		}
	}
}

#cart-products-table {
	.cart-products-header {
		> div {
			color: @MineShaft;
			font-size: 16px;
			line-height: 20px;
			.font-bold;
		}
	}

	.cart-products-list {
		.cart-products-list-item {
			> div.cart-item-amount-holder {
				.cart-item-decrement,
				.cart-item-increment {
					background: @Amaranth;
					color: @white;
				}

				.cart-item-amount {
					background: @white;
					border-color: @Amaranth;
					width: 60px;
					padding-left: 0;
					padding-right: 0;
					color: @MineShaft;
					.placeholder(@MineShaft);
				}
			}
		}
	}
}

.row-actions {
	padding: 10px 0;
}

#cart-data {
	.grid-cep {
		width: 140px;

		input {
			color: @MineShaft;
			margin-top: 2px;
			margin-bottom: 2px;
			font-size: 14px;
			line-height: 20px;
			padding-left: 10px;
			padding-right: 10px;
			background: @white;
			border-color: @Amaranth;
			.placeholder(@MineShaft);
		}
	}

	#label-link-coupon {
		line-height: 40px;
		color: @MineShaft;
		margin-top: 0px;
		.font-regular;
	}

	.cart-data-coupon-holder {
		a{
			&.link {
				line-height: 60px;
			}
		}
		.grid-2 {
			width: 160px;
			margin-right: 20px;

			input {
				color: @MineShaft;
				margin-top: 2px;
				margin-bottom: 2px;
				font-size: 14px;
				line-height: 20px;
				padding-left: 10px;
				padding-right: 10px;
				background: @white;
				border-color: @Amaranth;
				.placeholder(@MineShaft);
			}
		}

		.btn {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.cart-info {
		padding-bottom: 10px;

		b {
			color: @MineShaft;
			.font-bold;
		}
	}

	.cart-data-total {
		p {
			color: @MineShaft;
			.font-bold;

			b {
				color: @MineShaft;
			}
		}
	}
}

.list-shipping {
	.list-shipping-item {
		border-width: 2px;
		padding-left: 15px;
		padding-right: 15px;

		&.active {
			border-color: @Amaranth;
		}

		.list-shipping-row {
			.list-shipping-name {
				color: @MineShaft;
				.font-bold;
			}
		}
	}
}

#cart-email {
	h3, h4 {
		color: @MineShaft;
		.font-bold;
	}

	.cart-email-wrapper {
		input {
			height: auto;
			padding: 20px;
			color: @MineShaft;
			background: @white;
			.placeholder(@MineShaft);

			&.input-loading {
				background-position: 440px center !important;
				.transition(~"none !important");
			}
		}

		.btn {
			padding: 15px 40px;
			height: auto;
			right: 5px;
			top: 5px;
			bottom: 5px;
		}
	}
}

.box-cart-final {
	border-width: 2px;

	&.final-active {
		background: @white;
		border-color: @Amaranth;
	}

	.box-cart-header {
		.box-cart-title {
			font-size: 20px;
			line-height: 30px;
			color: @MineShaft;
			text-transform: uppercase;
			.font-bold;
		}
	}

	.card-expiration-separator {
		padding: 7px 10px;
	}
}

.box-order-summary {
	.box-order-summary-header {
		.box-order-summary-title {
			font-size: 20px;
			line-height: 30px;
			color: @MineShaft;
			text-transform: uppercase;
			.font-bold;
		}
	}
}

.list-payment {
	.list-payment-item {
		border-width: 2px;

		&.list-payment-active {
			border-color: @Amaranth;
		}
	}
}

.bandeiras-data {
	.cartao-bandeira-option{
		border-width: 2px;

		&.cartao-bandeira-option-active {
			border-color: @Amaranth;
		}
	}
}

.payment-title {
	color: @MineShaft;
	.font-bold;
}

#cart-final {
	input[type="email"], input[type="number"], input[type="password"], input[type="tel"], input[type="url"], input[type="text"], input[type="search"], input[type="creditcard"], input[type="cpf"] {
		padding: 4px 10px;
	}
}

/************************/

#personal-data {
	.box-cart-edit {
		bottom: 20px;
		left: 20px;
    }
}

//main: layout.less

.menu-right {
	ul {
		left: initial!important;
		right: 0;
	}
}

.menu-left {
	ul {
		left: 0;
		right: initial!important;
	}
}

//main: layout.less

.btn {
	background: @green;
	border: 0;
	line-height: 0;
	padding: 10px 15px;
	.border-radius(0);
	.transition;

	.btn-text {
		text-transform: uppercase;
		line-height: 20px;
		color: @white;
		font-size: 14px;
		.font-bold;
	}

	&.btn-mini {
		padding: 4px 10px 4px;
		.border-radius(0);

		.btn-text {
			font-size: 12px;
			line-height: 18px;
		}
	}

	&:hover {
		background: darken(@green, 15%);
	}
}

.btn.btn-cart-submit,
.btn.btn-next-step
.cart-slider-submit.btn,
.btn-share {
	background: @green;

	&:hover {
		background: darken(@green, 15%);
	}
}

//main: layout.less

.footer-1 {
	background: url("@{bg-social}");
	width: 100%;
	height: 355px;
}

.footer-2, .footer-3 {
	padding: 20px 0;
}

.footer-4, .copyright {
	background: @DarkMineShaft;
}

#footer-main {
    margin: 0!important;
	padding: 0!important;

	.footer-main-inner {
		background: @OtherMineShaft;
		color: @white;

		.title-footer {
			color: @AmaranthSecundary;
			font-size: 24px;
			line-height: 38px;
			text-transform: uppercase;
			.font-regular;
		}

		.element-social-footer {
			display: inline-block;
			padding: 130px 0;
			margin: 0 auto;
			width: 100%;

			.title-footer {
				text-align: center;
				text-transform: uppercase;
				line-height: 40px;
				font-size: 20px;
				color: @white;
				.font-extralight;
			}

			.social-footer-inner {
				width: 35%;
				margin: 0 auto;

				.social-footer-list {
					display: flex;
					justify-content: space-between;

					li {
						a {
							position: relative;
							margin: 0 auto;
							text-align: center;
							display: block;
							height: 30px;
							width: 90px;
							border: 2px solid @white;
							padding: 10px 35px;

							.social-footer-text {
								text-align: left;
								text-transform: uppercase;
								position: relative;
								color: @white;
								display: block;
								font-size: 16px;
								line-height: 30px;
								.font-regular;
								.fab;

								&::before {
									content: "";
									display: inline-block;
									position: absolute;
									right: 0;
								}
							}

							&.footer-instagram {
								.social-footer-text {
									&:before {
										content: "\f16d";
									}
								}
							}

							&.footer-facebook {
								.social-footer-text {
									&:before {
										content: "\f39e";
									}
								}
							}
						}
					}
				}
			}
		}

		.element-menu-footer {
			position: relative;
			width: 100%;

			#menu-footer {
				position: relative;
				width: 100%;

				.menu-footer-list {
					position: relative;
					width: 100%;

					>li {
						position: relative;
						display: inline-block;
						width: 50%;
						float: left;

						>a {
							color: @AmaranthSecundary;
							font-size: 24px;
							line-height: 38px;
							text-transform: uppercase;
							.font-regular;
						}

						>ul {
							>li {
								>a {
									color: @white;
									font-size: 20px;
									line-height: 30px;
									.font-light;
								}
							}
						}
					}
				}
			}
		}

		.element-payment-methods {
			position: relative;
			text-align: center;
			width: 100%;

			.title-footer {
				font-size: 24px;
				line-height: 38px;
				text-align: center;
				padding: 10px 0 30px;
			}

			.payment-methods-inner {
				position: relative;
				width: 100%;

				.payment-methods-list {
					display: inline-block;
					width: auto;

					li {
						margin: 0 5px 10px 5px;
						border: 1px solid @white;
						background: @white;
						.border-radius(5px);
					}
				}
			}
		}

		.element-phone {
			.phone-inner {
				line-height: 24px;
				font-size: 16px;
				.font-light;

				.info-phone {
					font-size: 20px;
					line-height: 30px;
					.fas;

					&::before {
						content: "\f095";
						margin-right: 5px;
					}

					&.info-whatsapp {
						.fab;

						&::before {
							content: "\f232";
						}

						.info-label-whatsapp {
							display: none;
						}
					}
				}

				.info-time, .info-email {
					font-size: 20px;
					line-height: 30px;
				}

				.info-time {
					margin-top: 10px;
				}
			}
		}

		.element-name-cnpj {
			padding: 20px 0;
			position: relative;
			width: 100%;

			img {
				padding: 20px 0;
				display: block;
				margin: 0 auto;
			}

			.title-footer {
				display: none;
			}

			.name-cnpj-inner {
				text-align: center;
				line-height: 24px;
				font-size: 14px;
				color: @IceGray;
				.font-light;
			}
		}
	}

	.copyright {
		text-align: center;
		color: @white;
	}
}

//main: layout.less

.element-breadcrumb {
	margin: 40px 0;

	.breadcrumb {
		ul {
			font-size: 15px;
			line-height: 20px;

			&.breadcrumb-list {
				text-transform: uppercase;
				font-size: 15px;
				line-height: 20px;
				color: @MineShaft;
				.font-regular;

				li {
					span, h2 {
						font-size: 15px;
						line-height: 20px;
					}

					&.active {
						a {
							font-size: 18px;
							line-height: 24px;
							color: @Amaranth;
							.font-bold;
						}
					}
				}
			}
		}
	}
}

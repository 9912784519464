//main: layout.less

#nav-bottom {
	.element-banner-bottom {
		position: relative;
		display: inline-block;

		.banner-bottom-inner {
			#banner-bottom {
				.banner-bottom-item {
					&:first-child {
						float: left;
						margin-right: auto;
						display: block;
					}

					&:last-child {
						float: right;
						margin-left: auto;
						display: block;
					}

					a {
						p {
							display: none;
						}
					}
				}
			}
		}
	}

	.element-newsletter-bottom {
		margin-bottom: 0!important;
		background: @OtherAmarath;
		width: 100%;
		height: 270px;
		overflow: hidden;

		.newsletter-inner {
			position: relative;

			.header-box-content {

				padding: 140px 0 90px;
				display: inline-block;
				float: left;
				width: 40%;

				.title-secundary {
					text-transform: uppercase;
					line-height: 40px;
					font-size: 30px;
					color: @white;
				}
			}

			.desc-newsletter {
				position: absolute;
				top: 190px;
				left: 0;
				text-transform: uppercase;
				display: inline-block;
				line-height: 20px;
				font-size: 20px;
				color: @white;
				float: left;
				max-width: 25%;
				.font-extralight;
			}

			.newsletter-form {
				display: inline-block;
				float: right;
				width: 60%;

				form {
					width: 100%;
					padding: 140px 0 90px;
					display: inline-block;

					.holder-email {
						width: 80%;

						.newsletter-email {
							color: @white;
							padding: 25px 10px;
							background: transparent;
							border: 2px solid @white;
							text-transform: uppercase;
							.placeholder(@white);
						}
					}

					.newsletter-submit {
						.btn-newsletter-submit {
							background: darken(@OtherAmarath, 15%);
							opacity: 1;
							height: 68px;
							width: 68px;
							.border-radius(5%);

							.icon-newsletter {
								display: block;
								width: 40px;
								height: 40px;

								&::before {
									content: "";
									background: url("@{bgs}");
									background-repeat: no-repeat;
									background-position: -50px -50px;
									display: block;
									width: 40px;
									height: 40px;
								}
							}

							.btn-text {
								display: none;
							}

							&:hover {
								opacity: .8;
							}
						}
					}

					.holder-name {
						display: none;
					}
				}
			}

			&::before {
				content: "";
				display: block;
				position: absolute;
				background: url("@{ghost}");
				background-repeat: no-repeat;
				width: 721px;
				height: 327px;
			}
		}
	}
}

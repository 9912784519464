// main: layout.less

/* Z-index */

@z-index-shopping-cart: 11;
//@z-index-header:		1000;
@z-index-modal-mask:	999998;
@z-index-modal: 		999999;
@z-index-header:		10;
@z-index-menu-category: 9;

/* URLs */

@url: "..";
//@url: "https://cdn.simplo7.net/static/themed/LoucoPorCarro/webroot";

@img:		"@{url}/img";
@bgs:		"@{img}/bg-sprite.png";
@bg-social:	"@{img}/social-bg.png";
@ghost:		"@{img}/newsletter-ghost.png";
@fonts: "https://cdn.simplo7.net/static/webroot/fonts";

/* Colors */
@black:		 			#000000;
@white:		 			#ffffff;
@CodGray:				#171717;
@IceGray:				#dfdfdf;
@gray:					#919191;
@MineShaft:				#373435;
@OtherMineShaft:		#323232;
@DarkMineShaft:			#2C2C2C;
@Purple:				#23181c;
@Blue:					#0c4bea;
@green:					#13b526;
@Amaranth:				#e82067;
@AmaranthSecundary:		#ee33a5;
@OtherAmarath:			#d22d91;
@AnotherAmarath:		#ea0c94;

@font-face {
	font-family: 'poppins_extralight';
	src: url("@{fonts}/poppins_extralight.eot");
	src: url("@{fonts}/poppins_extralight.eot?#iefix") format("embedded-opentype"),
		 url("@{fonts}/poppins_extralight.woff2") format("woff2"),
		 url("@{fonts}/poppins_extralight.woff") format("woff"),
		 url("@{fonts}/poppins_extralight.ttf") format("truetype"),
		 url("@{fonts}/poppins_extralight.svg#poppins_light") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppins_light';
	src: url("@{fonts}/poppins_light.eot");
	src: url("@{fonts}/poppins_light.eot?#iefix") format("embedded-opentype"),
		 url("@{fonts}/poppins_light.woff2") format("woff2"),
		 url("@{fonts}/poppins_light.woff") format("woff"),
		 url("@{fonts}/poppins_light.ttf") format("truetype"),
		 url("@{fonts}/poppins_light.svg#poppins_light") format("svg");
	font-weight: normal;
;	font-style: normal;
}

@font-face {
	font-family: 'poppins_regular';
	src: url("@{fonts}/poppins_regular.eot");
	src: url("@{fonts}/poppins_regular.eot?#iefix") format("embedded-opentype"),
		 url("@{fonts}/poppins_regular.woff2") format("woff2"),
		 url("@{fonts}/poppins_regular.woff") format("woff"),
		 url("@{fonts}/poppins_regular.ttf") format("truetype"),
		 url("@{fonts}/poppins_regular.svg#poppins_regular") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppins_medium';
	src: url("@{fonts}/poppins_medium.eot");
	src: url("@{fonts}/poppins_medium.eot?#iefix") format("embedded-opentype"),
		 url("@{fonts}/poppins_medium.woff2") format("woff2"),
		 url("@{fonts}/poppins_medium.woff") format("woff"),
		 url("@{fonts}/poppins_medium.ttf") format("truetype"),
		 url("@{fonts}/poppins_medium.svg#poppins_medium") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppins_semibold';
	src: url("@{fonts}/poppins_semibold.eot");
	src: url("@{fonts}/poppins_semibold.eot?#iefix") format("embedded-opentype"),
		 url("@{fonts}/poppins_semibold.woff2") format("woff2"),
		 url("@{fonts}/poppins_semibold.woff") format("woff"),
		 url("@{fonts}/poppins_semibold.ttf") format("truetype"),
		 url("@{fonts}/poppins_semibold.svg#poppins_black") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'poppins_bold';
	src: url("@{fonts}/poppins_bold.eot");
	src: url("@{fonts}/poppins_bold.eot?#iefix") format("embedded-opentype"),
		 url("@{fonts}/poppins_bold.woff2") format("woff2"),
		 url("@{fonts}/poppins_bold.woff") format("woff"),
		 url("@{fonts}/poppins_bold.ttf") format("truetype"),
		 url("@{fonts}/poppins_bold.svg#poppins_bold") format("svg");
	font-weight: normal;
	font-style: normal;
}

.font-extralight {
	font-family: 'poppins_extralight', Sans-serif;
	font-weight: normal;
}

.font-light {
	font-family: 'poppins_light', Sans-serif;
	font-weight: normal;
}

.font-regular {
	font-family: 'poppins_regular', Sans-serif;
	font-weight: normal;
}

.font-medium {
	font-family: 'poppins_medium', Sans-serif;
	font-weight: normal;
}

.font-semibold {
	font-family: 'poppins_semibold', Sans-serif;
	font-weight: normal;
}

.font-bold {
	font-family: 'poppins_bold', Sans-serif;
	font-weight: normal;
}

.arial {
	font-family: Arial, Sans-serif;
}

/*.base-gradient {
	.gradientV(@green, @dark-green);
}*/

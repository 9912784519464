//main: layout.less

@font-face {
	font-family: 'FontAwesomeBrands';
		src: url("@{fonts}/fa-brands-400.eot");
		src: url("@{fonts}/fa-brands-400.eot?#iefix") format("embedded-opentype"),
			 url("@{fonts}/fa-brands-400.woff2") format("woff2"),
			 url("@{fonts}/fa-brands-400.woff") format("woff"),
			 url("@{fonts}/fa-brands-400.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FontAwesomeRegular';
		src: url("@{fonts}/fa-regular-400.eot");
		src: url("@{fonts}/fa-regular-400.eot?#iefix") format("embedded-opentype"),
			 url("@{fonts}/fa-regular-400.woff2") format("woff2"),
			 url("@{fonts}/fa-regular-400.woff") format("woff"),
			 url("@{fonts}/fa-regular-400.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'FontAwesomeSolid';
		src: url("@{fonts}/fa-solid-900.eot");
		src: url("@{fonts}/fa-solid-900.eot?#iefix") format("embedded-opentype"),
			 url("@{fonts}/fa-solid-900.woff2") format("woff2"),
			 url("@{fonts}/fa-solid-900.woff") format("woff"),
			 url("@{fonts}/fa-solid-900.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

.fa, .fas, .fab {
	&:before {
		display: inline-block;
		font-family: 'FontAwesomeSolid';
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
	}
}

.far {
	&:before {
		font-family: 'FontAwesomeRegular';
	}
}

.fab {
	&:before {
		font-family: 'FontAwesomeBrands';
	}
}

.fas {
	&:before {
		font-family: 'FontAwesomeSolid';
	}
}

.fa-500px:before { content: "\f26e"; }
.fa-activitypub:before { content: "\f2f2"; }
.fa-address-book:before { content: "\f2b9"; }
.fa-address-book-o:before { content: "\f2ba"; }
.fa-address-card:before { content: "\f2bb"; }
.fa-address-card-o:before { content: "\f2bc"; }
.fa-adjust:before { content: "\f042"; }
.fa-adn:before { content: "\f170"; }
.fa-adobe:before { content: "\f778"; }
.fa-align-center:before { content: "\f037"; }
.fa-align-justify:before { content: "\f039"; }
.fa-align-left:before { content: "\f036"; }
.fa-align-right:before { content: "\f038"; }
.fa-amazon:before { content: "\f270"; }
.fa-ambulance:before { content: "\f0f9"; }
.fa-american-sign-language-interpreting:before { content: "\f2a3"; }
.fa-anchor:before { content: "\f13d"; }
.fa-android:before { content: "\f17b"; }
.fa-angellist:before { content: "\f209"; }
.fa-angle-double-down:before { content: "\f103"; }
.fa-angle-double-left:before { content: "\f100"; }
.fa-angle-double-right:before { content: "\f101"; }
.fa-angle-double-up:before { content: "\f102"; }
.fa-angle-down:before { content: "\f107"; }
.fa-angle-left:before { content: "\f104"; }
.fa-angle-right:before { content: "\f105"; }
.fa-angle-up:before { content: "\f106"; }
.fa-apple:before { content: "\f179"; }
.fa-archive:before { content: "\f187"; }
.fa-archive-org:before { content: "\f2fc"; }
.fa-archlinux:before { content: "\f323"; }
.fa-area-chart:before { content: "\f1fe"; }
.fa-arrow-circle-down:before { content: "\f0ab"; }
.fa-arrow-circle-left:before { content: "\f0a8"; }
.fa-arrow-circle-o-down:before { content: "\f01a"; }
.fa-arrow-circle-o-left:before { content: "\f190"; }
.fa-arrow-circle-o-right:before { content: "\f18e"; }
.fa-arrow-circle-o-up:before { content: "\f01b"; }
.fa-arrow-circle-right:before { content: "\f0a9"; }
.fa-arrow-circle-up:before { content: "\f0aa"; }
.fa-arrow-down:before { content: "\f063"; }
.fa-arrow-left:before { content: "\f060"; }
.fa-arrow-right:before { content: "\f061"; }
.fa-arrow-up:before { content: "\f062"; }
.fa-arrows:before { content: "\f047"; }
.fa-arrows-alt:before { content: "\f0b2"; }
.fa-arrows-h:before { content: "\f07e"; }
.fa-arrows-v:before { content: "\f07d"; }
.fa-artstation:before { content: "\f2ed"; }
.fa-asl-interpreting:before { content: "\f2a3"; }
.fa-assistive-listening-systems:before { content: "\f2a2"; }
.fa-asterisk:before { content: "\f069"; }
.fa-at:before { content: "\f1fa"; }
.fa-att:before { content: "\f31e"; }
.fa-audio-description:before { content: "\f29e"; }
.fa-automobile:before { content: "\f1b9"; }
.fa-backward:before { content: "\f04a"; }
.fa-balance-scale:before { content: "\f24e"; }
.fa-ban:before { content: "\f05e"; }
.fa-bandcamp:before { content: "\f2d5"; }
.fa-bank:before { content: "\f19c"; }
.fa-bar-chart:before { content: "\f080"; }
.fa-bar-chart-o:before { content: "\f080"; }
.fa-barcode:before { content: "\f02a"; }
.fa-bars:before { content: "\f0c9"; }
.fa-bath:before { content: "\f2cd"; }
.fa-bathtub:before { content: "\f2cd"; }
.fa-battery:before { content: "\f240"; }
.fa-battery-0:before { content: "\f244"; }
.fa-battery-1:before { content: "\f243"; }
.fa-battery-2:before { content: "\f242"; }
.fa-battery-3:before { content: "\f241"; }
.fa-battery-4:before { content: "\f240"; }
.fa-battery-empty:before { content: "\f244"; }
.fa-battery-full:before { content: "\f240"; }
.fa-battery-half:before { content: "\f242"; }
.fa-battery-quarter:before { content: "\f243"; }
.fa-battery-three-quarters:before { content: "\f241"; }
.fa-bed:before { content: "\f236"; }
.fa-beer:before { content: "\f0fc"; }
.fa-behance:before { content: "\f1b4"; }
.fa-behance-square:before { content: "\f1b5"; }
.fa-bell:before { content: "\f0a2"; }
.fa-bell-o:before { content: "\f0f3"; }
.fa-bell-slash:before { content: "\f1f6"; }
.fa-bell-slash-o:before { content: "\f1f7"; }
.fa-bicycle:before { content: "\f206"; }
.fa-binoculars:before { content: "\f1e5"; }
.fa-biometric:before { content: "\f32b"; }
.fa-birthday-cake:before { content: "\f1fd"; }
.fa-bitbucket:before { content: "\f171"; }
.fa-bitbucket-square:before { content: "\f172"; }
.fa-bitcoin:before { content: "\f15a"; }
.fa-black-tie:before { content: "\f27e"; }
.fa-blind:before { content: "\f29d"; }
.fa-bluetooth:before { content: "\f293"; }
.fa-bluetooth-b:before { content: "\f294"; }
.fa-bold:before { content: "\f032"; }
.fa-bolt:before { content: "\f0e7"; }
.fa-bomb:before { content: "\f1e2"; }
.fa-book:before { content: "\f02d"; }
.fa-bookmark:before { content: "\f02e"; }
.fa-bookmark-o:before { content: "\f097"; }
.fa-bootstrap:before { content: "\f315"; }
.fa-braille:before { content: "\f2a1"; }
.fa-briefcase:before { content: "\f0b1"; }
.fa-btc:before { content: "\f15a"; }
.fa-bug:before { content: "\f188"; }
.fa-building:before { content: "\f1ad"; }
.fa-building-o:before { content: "\f0f7"; }
.fa-bullhorn:before { content: "\f0a1"; }
.fa-bullseye:before { content: "\f140"; }
.fa-bus:before { content: "\f207"; }
.fa-buysellads:before { content: "\f20d"; }
.fa-c:before { content: "\f31c"; }
.fa-cab:before { content: "\f1ba"; }
.fa-calculator:before { content: "\f1ec"; }
.fa-calendar:before { content: "\f073"; }
.fa-calendar-check-o:before { content: "\f274"; }
.fa-calendar-minus-o:before { content: "\f272"; }
.fa-calendar-o:before { content: "\f133"; }
.fa-calendar-plus-o:before { content: "\f271"; }
.fa-calendar-times-o:before { content: "\f273"; }
.fa-camera:before { content: "\f030"; }
.fa-camera-retro:before { content: "\f083"; }
.fa-car:before { content: "\f1b9"; }
.fa-caret-down:before { content: "\f0d7"; }
.fa-caret-left:before { content: "\f0d9"; }
.fa-caret-right:before { content: "\f0da"; }
.fa-caret-square-o-down:before { content: "\f150"; }
.fa-caret-square-o-left:before { content: "\f191"; }
.fa-caret-square-o-right:before { content: "\f152"; }
.fa-caret-square-o-up:before { content: "\f151"; }
.fa-caret-up:before { content: "\f0d8"; }
.fa-cart-arrow-down:before { content: "\f218"; }
.fa-cart-plus:before { content: "\f217"; }
.fa-cc:before { content: "\f20a"; }
.fa-cc-amex:before { content: "\f1f3"; }
.fa-cc-diners-club:before { content: "\f24c"; }
.fa-cc-discover:before { content: "\f1f2"; }
.fa-cc-jcb:before { content: "\f24b"; }
.fa-cc-mastercard:before { content: "\f1f1"; }
.fa-cc-paypal:before { content: "\f1f4"; }
.fa-cc-stripe:before { content: "\f1f5"; }
.fa-cc-visa:before { content: "\f1f0"; }
.fa-certificate:before { content: "\f0a3"; }
.fa-chain:before { content: "\f0c1"; }
.fa-chain-broken:before { content: "\f127"; }
.fa-check:before { content: "\f00c"; }
.fa-check-circle:before { content: "\f058"; }
.fa-check-circle-o:before { content: "\f05d"; }
.fa-check-square:before { content: "\f14a"; }
.fa-check-square-o:before { content: "\f046"; }
.fa-chevron-circle-down:before { content: "\f13a"; }
.fa-chevron-circle-left:before { content: "\f137"; }
.fa-chevron-circle-right:before { content: "\f138"; }
.fa-chevron-circle-up:before { content: "\f139"; }
.fa-chevron-down:before { content: "\f078"; }
.fa-chevron-left:before { content: "\f053"; }
.fa-chevron-right:before { content: "\f054"; }
.fa-chevron-up:before { content: "\f077"; }
.fa-child:before { content: "\f1ae"; }
.fa-chrome:before { content: "\f268"; }
.fa-circle:before { content: "\f111"; }
.fa-circle-o:before { content: "\f10c"; }
.fa-circle-o-notch:before { content: "\f1ce"; }
.fa-circle-thin:before { content: "\f1db"; }
.fa-clipboard:before { content: "\f0ea"; }
.fa-clock-o:before { content: "\f017"; }
.fa-clone:before { content: "\f24d"; }
.fa-close:before { content: "\f00d"; }
.fa-closed-captioning:before { content: "\f20a"; }
.fa-cloud:before { content: "\f0c2"; }
.fa-cloud-download:before { content: "\f0ed"; }
.fa-cloud-upload:before { content: "\f0ee"; }
.fa-cny:before { content: "\f157"; }
.fa-code:before { content: "\f121"; }
.fa-code-fork:before { content: "\f126"; }
.fa-codepen:before { content: "\f1cb"; }
.fa-codiepie:before { content: "\f284"; }
.fa-coffee:before { content: "\f0f4"; }
.fa-cog:before { content: "\f013"; }
.fa-cogs:before { content: "\f085"; }
.fa-columns:before { content: "\f0db"; }
.fa-comment:before { content: "\f075"; }
.fa-comment-o:before { content: "\f0e5"; }
.fa-commenting:before { content: "\f27a"; }
.fa-commenting-o:before { content: "\f27b"; }
.fa-comments:before { content: "\f086"; }
.fa-comments-o:before { content: "\f0e6"; }
.fa-community:before { content: "\f0c0"; }
.fa-compass:before { content: "\f14e"; }
.fa-compress:before { content: "\f066"; }
.fa-connectdevelop:before { content: "\f20e"; }
.fa-contao:before { content: "\f26d"; }
.fa-copy:before { content: "\f0c5"; }
.fa-copyright:before { content: "\f1f9"; }
.fa-creative-commons:before { content: "\f25e"; }
.fa-credit-card:before { content: "\f09d"; }
.fa-credit-card-alt:before { content: "\f283"; }
.fa-crop:before { content: "\f125"; }
.fa-crosshairs:before { content: "\f05b"; }
.fa-css3:before { content: "\f13c"; }
.fa-cube:before { content: "\f1b2"; }
.fa-cubes:before { content: "\f1b3"; }
.fa-cut:before { content: "\f0c4"; }
.fa-cut-key:before { content: "\f2f7"; }
.fa-cutlery:before { content: "\f0f5"; }
.fa-dashboard:before { content: "\f0e4"; }
.fa-dashcube:before { content: "\f210"; }
.fa-database:before { content: "\f1c0"; }
.fa-deaf:before { content: "\f2a4"; }
.fa-deafness:before { content: "\f2a4"; }
.fa-debian:before { content: "\f2ff"; }
.fa-dedent:before { content: "\f03b"; }
.fa-delicious:before { content: "\f1a5"; }
.fa-desktop:before { content: "\f108"; }
.fa-dev-to:before { content: "\f316"; }
.fa-deviantart:before { content: "\f1bd"; }
.fa-diamond:before { content: "\f219"; }
.fa-diaspora:before { content: "\f2e5"; }
.fa-digg:before { content: "\f1a6"; }
.fa-digitalocean:before { content: "\f31d"; }
.fa-discord:before { content: "\f2ee"; }
.fa-discord-alt:before { content: "\f2ef"; }
.fa-dogmazic:before { content: "\f303"; }
.fa-dollar:before { content: "\f155"; }
.fa-dot-circle-o:before { content: "\f192"; }
.fa-download:before { content: "\f019"; }
.fa-dribbble:before { content: "\f17d"; }
.fa-drivers-license:before { content: "\f2c2"; }
.fa-drivers-license-o:before { content: "\f2c3"; }
.fa-dropbox:before { content: "\f16b"; }
.fa-drupal:before { content: "\f1a9"; }
.fa-edge:before { content: "\f282"; }
.fa-edit:before { content: "\f044"; }
.fa-eercast:before { content: "\f2da"; }
.fa-eject:before { content: "\f052"; }
.fa-ellipsis-h:before { content: "\f141"; }
.fa-ellipsis-v:before { content: "\f142"; }
.fa-emby:before { content: "\f319"; }
.fa-empire:before { content: "\f1d1"; }
.fa-envelope:before { content: "\f0e0"; }
.fa-envelope-o:before { content: "\f003"; }
.fa-envelope-open:before { content: "\f2b6"; }
.fa-envelope-open-o:before { content: "\f2b7"; }
.fa-envelope-square:before { content: "\f199"; }
.fa-envira:before { content: "\f299"; }
.fa-eraser:before { content: "\f12d"; }
.fa-ethereum:before { content: "\f2f3"; }
.fa-etsy:before { content: "\f2d7"; }
.fa-eur:before { content: "\f153"; }
.fa-euro:before { content: "\f153"; }
.fa-exchange:before { content: "\f0ec"; }
.fa-exclamation:before { content: "\f12a"; }
.fa-exclamation-circle:before { content: "\f06a"; }
.fa-exclamation-triangle:before { content: "\f071"; }
.fa-expand:before { content: "\f065"; }
.fa-expeditedssl:before { content: "\f23e"; }
.fa-external-link:before { content: "\f08e"; }
.fa-external-link-square:before { content: "\f14c"; }
.fa-eye:before { content: "\f06e"; }
.fa-eye-slash:before { content: "\f070"; }
.fa-eyedropper:before { content: "\f1fb"; }
.fa-f-droid:before { content: "\f32a"; }
.fa-fa:before { content: "\f2b4"; }
.fa-facebook:before { content: "\f09a"; }
.fa-facebook-f:before { content: "\f09a"; }
.fa-facebook-messenger:before { content: "\f2fe"; }
.fa-facebook-official:before { content: "\f230"; }
.fa-facebook-square:before { content: "\f082"; }
.fa-fast-backward:before { content: "\f049"; }
.fa-fast-forward:before { content: "\f050"; }
.fa-fax:before { content: "\f1ac"; }
.fa-feed:before { content: "\f09e"; }
.fa-female:before { content: "\f182"; }
.fa-ffmpeg:before { content: "\f30f"; }
.fa-fighter-jet:before { content: "\f0fb"; }
.fa-file:before { content: "\f15b"; }
.fa-file-archive-o:before { content: "\f1c6"; }
.fa-file-audio-o:before { content: "\f1c7"; }
.fa-file-code-o:before { content: "\f1c9"; }
.fa-file-epub:before { content: "\f321"; }
.fa-file-excel-o:before { content: "\f1c3"; }
.fa-file-image-o:before { content: "\f1c5"; }
.fa-file-movie-o:before { content: "\f1c8"; }
.fa-file-o:before { content: "\f016"; }
.fa-file-pdf-o:before { content: "\f1c1"; }
.fa-file-photo-o:before { content: "\f1c5"; }
.fa-file-picture-o:before { content: "\f1c5"; }
.fa-file-powerpoint-o:before { content: "\f1c4"; }
.fa-file-sound-o:before { content: "\f1c7"; }
.fa-file-text:before { content: "\f15c"; }
.fa-file-text-o:before { content: "\f0f6"; }
.fa-file-video-o:before { content: "\f1c8"; }
.fa-file-word-o:before { content: "\f1c2"; }
.fa-file-zip-o:before { content: "\f1c6"; }
.fa-files-o:before { content: "\f0c5"; }
.fa-film:before { content: "\f008"; }
.fa-filter:before { content: "\f0b0"; }
.fa-fire:before { content: "\f06d"; }
.fa-fire-extinguisher:before { content: "\f134"; }
.fa-firefox:before { content: "\f269"; }
.fa-first-order:before { content: "\f2b0"; }
.fa-flag:before { content: "\f024"; }
.fa-flag-checkered:before { content: "\f11e"; }
.fa-flag-o:before { content: "\f11d"; }
.fa-flash:before { content: "\f0e7"; }
.fa-flask:before { content: "\f0c3"; }
.fa-flickr:before { content: "\f16e"; }
.fa-floppy-o:before { content: "\f0c7"; }
.fa-folder:before { content: "\f07b"; }
.fa-folder-o:before { content: "\f114"; }
.fa-folder-open:before { content: "\f07c"; }
.fa-folder-open-o:before { content: "\f115"; }
.fa-font:before { content: "\f031"; }
.fa-font-awesome:before { content: "\f2b4"; }
.fa-fonticons:before { content: "\f280"; }
.fa-fork-awesome:before { content: "\f2e3"; }
.fa-fork-circle:before { content: "\f2e3"; }
.fa-fort-awesome:before { content: "\f286"; }
.fa-forumbee:before { content: "\f211"; }
.fa-forward:before { content: "\f04e"; }
.fa-foursquare:before { content: "\f180"; }
.fa-free-code-camp:before { content: "\f2c5"; }
.fa-freedombox:before { content: "\f2fd"; }
.fa-friendica:before { content: "\f2e6"; }
.fa-frown-o:before { content: "\f119"; }
.fa-futbol-o:before { content: "\f1e3"; }
.fa-gamepad:before { content: "\f11b"; }
.fa-gavel:before { content: "\f0e3"; }
.fa-gbp:before { content: "\f154"; }
.fa-ge:before { content: "\f1d1"; }
.fa-gear:before { content: "\f013"; }
.fa-gears:before { content: "\f085"; }
.fa-gem:before { content: "\f219"; }
.fa-genderless:before { content: "\f22d"; }
.fa-get-pocket:before { content: "\f265"; }
.fa-gg:before { content: "\f260"; }
.fa-gg-circle:before { content: "\f261"; }
.fa-gift:before { content: "\f06b"; }
.fa-gimp:before { content: "\f31b"; }
.fa-git:before { content: "\f1d3"; }
.fa-git-square:before { content: "\f1d2"; }
.fa-gitea:before { content: "\f31f"; }
.fa-github:before { content: "\f09b"; }
.fa-github-alt:before { content: "\f113"; }
.fa-github-square:before { content: "\f092"; }
.fa-gitlab:before { content: "\f296"; }
.fa-gittip:before { content: "\f184"; }
.fa-glass:before { content: "\f000"; }
.fa-glide:before { content: "\f2a5"; }
.fa-glide-g:before { content: "\f2a6"; }
.fa-globe:before { content: "\f0ac"; }
.fa-globe-e:before { content: "\f304"; }
.fa-globe-w:before { content: "\f305"; }
.fa-gnu-social:before { content: "\f2e7"; }
.fa-gnupg:before { content: "\f30d"; }
.fa-google:before { content: "\f1a0"; }
.fa-google-plus:before { content: "\f0d5"; }
.fa-google-plus-circle:before { content: "\f2b3"; }
.fa-google-plus-g:before { content: "\f0d5"; }
.fa-google-plus-official:before { content: "\f2b3"; }
.fa-google-plus-square:before { content: "\f0d4"; }
.fa-google-wallet:before { content: "\f1ee"; }
.fa-graduation-cap:before { content: "\f19d"; }
.fa-gratipay:before { content: "\f184"; }
.fa-grav:before { content: "\f2d6"; }
.fa-group:before { content: "\f0c0"; }
.fa-h-square:before { content: "\f0fd"; }
.fa-hackaday:before { content: "\f30a"; }
.fa-hacker-news:before { content: "\f1d4"; }
.fa-hackster:before { content: "\f326"; }
.fa-hand-grab-o:before { content: "\f255"; }
.fa-hand-lizard-o:before { content: "\f258"; }
.fa-hand-o-down:before { content: "\f0a7"; }
.fa-hand-o-left:before { content: "\f0a5"; }
.fa-hand-o-right:before { content: "\f0a4"; }
.fa-hand-o-up:before { content: "\f0a6"; }
.fa-hand-paper-o:before { content: "\f256"; }
.fa-hand-peace-o:before { content: "\f25b"; }
.fa-hand-pointer-o:before { content: "\f25a"; }
.fa-hand-rock-o:before { content: "\f255"; }
.fa-hand-scissors-o:before { content: "\f257"; }
.fa-hand-spock-o:before { content: "\f259"; }
.fa-hand-stop-o:before { content: "\f256"; }
.fa-handshake-o:before { content: "\f2b5"; }
.fa-hard-of-hearing:before { content: "\f2a4"; }
.fa-hashnode:before { content: "\f317"; }
.fa-hashtag:before { content: "\f292"; }
.fa-hdd-o:before { content: "\f0a0"; }
.fa-header:before { content: "\f1dc"; }
.fa-heading:before { content: "\f1dc"; }
.fa-headphones:before { content: "\f025"; }
.fa-heart:before { content: "\f004"; }
.fa-heart-o:before { content: "\f08a"; }
.fa-heartbeat:before { content: "\f21e"; }
.fa-history:before { content: "\f1da"; }
.fa-home:before { content: "\f015"; }
.fa-hospital-o:before { content: "\f0f8"; }
.fa-hotel:before { content: "\f236"; }
.fa-hourglass:before { content: "\f254"; }
.fa-hourglass-1:before { content: "\f251"; }
.fa-hourglass-2:before { content: "\f252"; }
.fa-hourglass-3:before { content: "\f253"; }
.fa-hourglass-end:before { content: "\f253"; }
.fa-hourglass-half:before { content: "\f252"; }
.fa-hourglass-o:before { content: "\f250"; }
.fa-hourglass-start:before { content: "\f251"; }
.fa-houzz:before { content: "\f27c"; }
.fa-html5:before { content: "\f13b"; }
.fa-hubzilla:before { content: "\f2eb"; }
.fa-i-cursor:before { content: "\f246"; }
.fa-id-badge:before { content: "\f2c1"; }
.fa-id-card:before { content: "\f2c2"; }
.fa-id-card-o:before { content: "\f2c3"; }
.fa-ils:before { content: "\f20b"; }
.fa-image:before { content: "\f03e"; }
.fa-imdb:before { content: "\f2d8"; }
.fa-inbox:before { content: "\f01c"; }
.fa-indent:before { content: "\f03c"; }
.fa-industry:before { content: "\f275"; }
.fa-info:before { content: "\f129"; }
.fa-info-circle:before { content: "\f05a"; }
.fa-inkscape:before { content: "\f312"; }
.fa-inr:before { content: "\f156"; }
.fa-instagram:before { content: "\f16d"; }
.fa-institution:before { content: "\f19c"; }
.fa-internet-explorer:before { content: "\f26b"; }
.fa-intersex:before { content: "\f224"; }
.fa-ioxhost:before { content: "\f208"; }
.fa-italic:before { content: "\f033"; }
.fa-jirafeau:before { content: "\f318"; }
.fa-joomla:before { content: "\f1aa"; }
.fa-joplin:before { content: "\f310"; }
.fa-jpy:before { content: "\f157"; }
.fa-jsfiddle:before { content: "\f1cc"; }
.fa-key:before { content: "\f084"; }
.fa-key-modern:before { content: "\f2f7"; }
.fa-keybase:before { content: "\f2f4"; }
.fa-keyboard-o:before { content: "\f11c"; }
.fa-krw:before { content: "\f159"; }
.fa-language:before { content: "\f1ab"; }
.fa-laptop:before { content: "\f109"; }
.fa-laravel:before { content: "\f30b"; }
.fa-lastfm:before { content: "\f202"; }
.fa-lastfm-square:before { content: "\f203"; }
.fa-leaf:before { content: "\f06c"; }
.fa-leanpub:before { content: "\f212"; }
.fa-legal:before { content: "\f0e3"; }
.fa-lemon-o:before { content: "\f094"; }
.fa-level-down:before { content: "\f149"; }
.fa-level-up:before { content: "\f148"; }
.fa-liberapay:before { content: "\f2e9"; }
.fa-liberapay-square:before { content: "\f2e8"; }
.fa-life-bouy:before { content: "\f1cd"; }
.fa-life-buoy:before { content: "\f1cd"; }
.fa-life-ring:before { content: "\f1cd"; }
.fa-life-saver:before { content: "\f1cd"; }
.fa-lightbulb-o:before { content: "\f0eb"; }
.fa-line-chart:before { content: "\f201"; }
.fa-link:before { content: "\f0c1"; }
.fa-linkedin:before { content: "\f0e1"; }
.fa-linkedin-square:before { content: "\f08c"; }
.fa-linode:before { content: "\f2b8"; }
.fa-linux:before { content: "\f17c"; }
.fa-list:before { content: "\f03a"; }
.fa-list-alt:before { content: "\f022"; }
.fa-list-ol:before { content: "\f0cb"; }
.fa-list-ul:before { content: "\f0ca"; }
.fa-location-arrow:before { content: "\f124"; }
.fa-lock:before { content: "\f023"; }
.fa-long-arrow-down:before { content: "\f175"; }
.fa-long-arrow-left:before { content: "\f177"; }
.fa-long-arrow-right:before { content: "\f178"; }
.fa-long-arrow-up:before { content: "\f176"; }
.fa-low-vision:before { content: "\f2a8"; }
.fa-magic:before { content: "\f0d0"; }
.fa-magnet:before { content: "\f076"; }
.fa-mail-forward:before { content: "\f064"; }
.fa-mail-reply:before { content: "\f112"; }
.fa-mail-reply-all:before { content: "\f122"; }
.fa-male:before { content: "\f183"; }
.fa-map:before { content: "\f279"; }
.fa-map-marker:before { content: "\f041"; }
.fa-map-o:before { content: "\f278"; }
.fa-map-pin:before { content: "\f276"; }
.fa-map-signs:before { content: "\f277"; }
.fa-mars:before { content: "\f222"; }
.fa-mars-double:before { content: "\f227"; }
.fa-mars-stroke:before { content: "\f229"; }
.fa-mars-stroke-h:before { content: "\f22b"; }
.fa-mars-stroke-v:before { content: "\f22a"; }
.fa-mastodon:before { content: "\f2e1"; }
.fa-mastodon-alt:before { content: "\f2e2"; }
.fa-mastodon-square:before { content: "\f300"; }
.fa-matrix-org:before { content: "\f313"; }
.fa-maxcdn:before { content: "\f136"; }
.fa-meanpath:before { content: "\f20c"; }
.fa-medium:before { content: "\f23a"; }
.fa-medium-square:before { content: "\f2f8"; }
.fa-medkit:before { content: "\f0fa"; }
.fa-meetup:before { content: "\f2e0"; }
.fa-meh-o:before { content: "\f11a"; }
.fa-mercury:before { content: "\f223"; }
.fa-microchip:before { content: "\f2db"; }
.fa-microphone:before { content: "\f130"; }
.fa-microphone-slash:before { content: "\f131"; }
.fa-minus:before { content: "\f068"; }
.fa-minus-circle:before { content: "\f056"; }
.fa-minus-square:before { content: "\f146"; }
.fa-minus-square-o:before { content: "\f147"; }
.fa-mixcloud:before { content: "\f289"; }
.fa-mobile:before { content: "\f10b"; }
.fa-mobile-phone:before { content: "\f10b"; }
.fa-modx:before { content: "\f285"; }
.fa-money:before { content: "\f0d6"; }
.fa-moon:before { content: "\f328"; }
.fa-moon-o:before { content: "\f186"; }
.fa-mortar-board:before { content: "\f19d"; }
.fa-motorcycle:before { content: "\f21c"; }
.fa-mouse-pointer:before { content: "\f245"; }
.fa-music:before { content: "\f001"; }
.fa-navicon:before { content: "\f0c9"; }
.fa-neuter:before { content: "\f22c"; }
.fa-newspaper-o:before { content: "\f1ea"; }
.fa-nextcloud:before { content: "\f306"; }
.fa-nextcloud-square:before { content: "\f307"; }
.fa-nodejs:before { content: "\f308"; }
.fa-object-group:before { content: "\f247"; }
.fa-object-ungroup:before { content: "\f248"; }
.fa-odnoklassniki:before { content: "\f263"; }
.fa-odnoklassniki-square:before { content: "\f264"; }
.fa-opencart:before { content: "\f23d"; }
.fa-openid:before { content: "\f19b"; }
.fa-opera:before { content: "\f26a"; }
.fa-optin-monster:before { content: "\f23c"; }
.fa-outdent:before { content: "\f03b"; }
.fa-pagelines:before { content: "\f18c"; }
.fa-paint-brush:before { content: "\f1fc"; }
.fa-paper-plane:before { content: "\f1d8"; }
.fa-paper-plane-o:before { content: "\f1d9"; }
.fa-paperclip:before { content: "\f0c6"; }
.fa-paragraph:before { content: "\f1dd"; }
.fa-paste:before { content: "\f0ea"; }
.fa-patreon:before { content: "\f2f0"; }
.fa-pause:before { content: "\f04c"; }
.fa-pause-circle:before { content: "\f28b"; }
.fa-pause-circle-o:before { content: "\f28c"; }
.fa-paw:before { content: "\f1b0"; }
.fa-paypal:before { content: "\f1ed"; }
.fa-peertube:before { content: "\f2e4"; }
.fa-pencil:before { content: "\f040"; }
.fa-pencil-square:before { content: "\f14b"; }
.fa-pencil-square-o:before { content: "\f044"; }
.fa-percent:before { content: "\f295"; }
.fa-phone:before { content: "\f095"; }
.fa-phone-square:before { content: "\f098"; }
.fa-phone-volume:before { content: "\f2a0"; }
.fa-photo:before { content: "\f03e"; }
.fa-php:before { content: "\f30e"; }
.fa-picture-o:before { content: "\f03e"; }
.fa-pie-chart:before { content: "\f200"; }
.fa-pinterest:before { content: "\f0d2"; }
.fa-pinterest-p:before { content: "\f231"; }
.fa-pinterest-square:before { content: "\f0d3"; }
.fa-pixelfed:before { content: "\f314"; }
.fa-plane:before { content: "\f072"; }
.fa-play:before { content: "\f04b"; }
.fa-play-circle:before { content: "\f144"; }
.fa-play-circle-o:before { content: "\f01d"; }
.fa-pleroma:before { content: "\f324"; }
.fa-plug:before { content: "\f1e6"; }
.fa-plus:before { content: "\f067"; }
.fa-plus-circle:before { content: "\f055"; }
.fa-plus-square:before { content: "\f0fe"; }
.fa-plus-square-o:before { content: "\f196"; }
.fa-podcast:before { content: "\f2ce"; }
.fa-pound:before { content: "\f154"; }
.fa-power-off:before { content: "\f011"; }
.fa-print:before { content: "\f02f"; }
.fa-product-hunt:before { content: "\f288"; }
.fa-puzzle-piece:before { content: "\f12e"; }
.fa-python:before { content: "\f322"; }
.fa-qq:before { content: "\f1d6"; }
.fa-qrcode:before { content: "\f029"; }
.fa-question:before { content: "\f128"; }
.fa-question-circle:before { content: "\f059"; }
.fa-question-circle-o:before { content: "\f29c"; }
.fa-quora:before { content: "\f2c4"; }
.fa-quote-left:before { content: "\f10d"; }
.fa-quote-right:before { content: "\f10e"; }
.fa-ra:before { content: "\f1d0"; }
.fa-random:before { content: "\f074"; }
.fa-ravelry:before { content: "\f2d9"; }
.fa-react:before { content: "\f302"; }
.fa-rebel:before { content: "\f1d0"; }
.fa-recycle:before { content: "\f1b8"; }
.fa-reddit:before { content: "\f1a1"; }
.fa-reddit-alien:before { content: "\f281"; }
.fa-reddit-square:before { content: "\f1a2"; }
.fa-refresh:before { content: "\f021"; }
.fa-registered:before { content: "\f25d"; }
.fa-remove:before { content: "\f00d"; }
.fa-renren:before { content: "\f18b"; }
.fa-reorder:before { content: "\f0c9"; }
.fa-repeat:before { content: "\f01e"; }
.fa-reply:before { content: "\f112"; }
.fa-reply-all:before { content: "\f122"; }
.fa-resistance:before { content: "\f1d0"; }
.fa-retweet:before { content: "\f079"; }
.fa-rmb:before { content: "\f157"; }
.fa-road:before { content: "\f018"; }
.fa-rocket:before { content: "\f135"; }
.fa-rotate-left:before { content: "\f0e2"; }
.fa-rotate-right:before { content: "\f01e"; }
.fa-rouble:before { content: "\f158"; }
.fa-rss:before { content: "\f09e"; }
.fa-rss-square:before { content: "\f143"; }
.fa-rub:before { content: "\f158"; }
.fa-ruble:before { content: "\f158"; }
.fa-rupee:before { content: "\f156"; }
.fa-s15:before { content: "\f2cd"; }
.fa-safari:before { content: "\f267"; }
.fa-save:before { content: "\f0c7"; }
.fa-scissors:before { content: "\f0c4"; }
.fa-scribd:before { content: "\f28a"; }
.fa-scuttlebutt:before { content: "\f2ea"; }
.fa-search:before { content: "\f002"; }
.fa-search-minus:before { content: "\f010"; }
.fa-search-plus:before { content: "\f00e"; }
.fa-sellsy:before { content: "\f213"; }
.fa-send:before { content: "\f1d8"; }
.fa-send-o:before { content: "\f1d9"; }
.fa-server:before { content: "\f233"; }
.fa-shaarli:before { content: "\f2f5"; }
.fa-shaarli-o:before { content: "\f2f6"; }
.fa-share:before { content: "\f064"; }
.fa-share-alt:before { content: "\f1e0"; }
.fa-share-alt-square:before { content: "\f1e1"; }
.fa-share-square:before { content: "\f14d"; }
.fa-share-square-o:before { content: "\f045"; }
.fa-shekel:before { content: "\f20b"; }
.fa-sheqel:before { content: "\f20b"; }
.fa-shield:before { content: "\f132"; }
.fa-ship:before { content: "\f21a"; }
.fa-shirtsinbulk:before { content: "\f214"; }
.fa-shopping-bag:before { content: "\f290"; }
.fa-shopping-basket:before { content: "\f291"; }
.fa-shopping-cart:before { content: "\f07a"; }
.fa-shower:before { content: "\f2cc"; }
.fa-sign-in:before { content: "\f090"; }
.fa-sign-language:before { content: "\f2a7"; }
.fa-sign-out:before { content: "\f08b"; }
.fa-signal:before { content: "\f012"; }
.fa-signalapp:before { content: "\f30c"; }
.fa-signing:before { content: "\f2a7"; }
.fa-simplybuilt:before { content: "\f215"; }
.fa-sitemap:before { content: "\f0e8"; }
.fa-skyatlas:before { content: "\f216"; }
.fa-skype:before { content: "\f17e"; }
.fa-slack:before { content: "\f198"; }
.fa-sliders:before { content: "\f1de"; }
.fa-slideshare:before { content: "\f1e7"; }
.fa-smile-o:before { content: "\f118"; }
.fa-snapchat:before { content: "\f2ab"; }
.fa-snapchat-ghost:before { content: "\f2ac"; }
.fa-snapchat-square:before { content: "\f2ad"; }
.fa-snowdrift:before { content: "\f2f1"; }
.fa-snowflake-o:before { content: "\f2dc"; }
.fa-soccer-ball-o:before { content: "\f1e3"; }
.fa-social-home:before { content: "\f2ec"; }
.fa-sort:before { content: "\f0dc"; }
.fa-sort-alpha-asc:before { content: "\f15d"; }
.fa-sort-alpha-desc:before { content: "\f15e"; }
.fa-sort-alpha-down:before { content: "\f15d"; }
.fa-sort-alpha-up:before { content: "\f15e"; }
.fa-sort-amount-asc:before { content: "\f160"; }
.fa-sort-amount-desc:before { content: "\f161"; }
.fa-sort-amount-down:before { content: "\f160"; }
.fa-sort-amount-up:before { content: "\f161"; }
.fa-sort-asc:before { content: "\f0de"; }
.fa-sort-desc:before { content: "\f0dd"; }
.fa-sort-down:before { content: "\f0dd"; }
.fa-sort-numeric-asc:before { content: "\f162"; }
.fa-sort-numeric-desc:before { content: "\f163"; }
.fa-sort-numeric-down:before { content: "\f162"; }
.fa-sort-numeric-up:before { content: "\f163"; }
.fa-sort-up:before { content: "\f0de"; }
.fa-soundcloud:before { content: "\f1be"; }
.fa-space-shuttle:before { content: "\f197"; }
.fa-spell-check:before { content: "\f327"; }
.fa-spinner:before { content: "\f110"; }
.fa-spoon:before { content: "\f1b1"; }
.fa-spotify:before { content: "\f1bc"; }
.fa-square:before { content: "\f0c8"; }
.fa-square-o:before { content: "\f096"; }
.fa-ssb:before { content: "\f2ea"; }
.fa-stack-exchange:before { content: "\f18d"; }
.fa-stack-overflow:before { content: "\f16c"; }
.fa-star:before { content: "\f005"; }
.fa-star-half:before { content: "\f089"; }
.fa-star-half-empty:before { content: "\f123"; }
.fa-star-half-full:before { content: "\f123"; }
.fa-star-half-o:before { content: "\f123"; }
.fa-star-o:before { content: "\f006"; }
.fa-steam:before { content: "\f1b6"; }
.fa-steam-square:before { content: "\f1b7"; }
.fa-step-backward:before { content: "\f048"; }
.fa-step-forward:before { content: "\f051"; }
.fa-stethoscope:before { content: "\f0f1"; }
.fa-sticky-note:before { content: "\f249"; }
.fa-sticky-note-o:before { content: "\f24a"; }
.fa-stop:before { content: "\f04d"; }
.fa-stop-circle:before { content: "\f28d"; }
.fa-stop-circle-o:before { content: "\f28e"; }
.fa-street-view:before { content: "\f21d"; }
.fa-strikethrough:before { content: "\f0cc"; }
.fa-stumbleupon:before { content: "\f1a4"; }
.fa-stumbleupon-circle:before { content: "\f1a3"; }
.fa-subscript:before { content: "\f12c"; }
.fa-subway:before { content: "\f239"; }
.fa-suitcase:before { content: "\f0f2"; }
.fa-sun:before { content: "\f329"; }
.fa-sun-o:before { content: "\f185"; }
.fa-superpowers:before { content: "\f2dd"; }
.fa-superscript:before { content: "\f12b"; }
.fa-support:before { content: "\f1cd"; }
.fa-sync:before { content: "\f021"; }
.fa-syncthing:before { content: "\f311"; }
.fa-table:before { content: "\f0ce"; }
.fa-tablet:before { content: "\f10a"; }
.fa-tachometer:before { content: "\f0e4"; }
.fa-tag:before { content: "\f02b"; }
.fa-tags:before { content: "\f02c"; }
.fa-tasks:before { content: "\f0ae"; }
.fa-taxi:before { content: "\f1ba"; }
.fa-telegram:before { content: "\f2c6"; }
.fa-television:before { content: "\f26c"; }
.fa-tencent-weibo:before { content: "\f1d5"; }
.fa-terminal:before { content: "\f120"; }
.fa-text-height:before { content: "\f034"; }
.fa-text-width:before { content: "\f035"; }
.fa-th:before { content: "\f00a"; }
.fa-th-large:before { content: "\f009"; }
.fa-th-list:before { content: "\f00b"; }
.fa-themeisle:before { content: "\f2b2"; }
.fa-thermometer:before { content: "\f2c7"; }
.fa-thermometer-0:before { content: "\f2cb"; }
.fa-thermometer-1:before { content: "\f2ca"; }
.fa-thermometer-2:before { content: "\f2c9"; }
.fa-thermometer-3:before { content: "\f2c8"; }
.fa-thermometer-4:before { content: "\f2c7"; }
.fa-thermometer-empty:before { content: "\f2cb"; }
.fa-thermometer-full:before { content: "\f2c7"; }
.fa-thermometer-half:before { content: "\f2c9"; }
.fa-thermometer-quarter:before { content: "\f2ca"; }
.fa-thermometer-three-quarters:before { content: "\f2c8"; }
.fa-thumb-tack:before { content: "\f08d"; }
.fa-thumbs-down:before { content: "\f165"; }
.fa-thumbs-o-down:before { content: "\f088"; }
.fa-thumbs-o-up:before { content: "\f087"; }
.fa-thumbs-up:before { content: "\f164"; }
.fa-ticket:before { content: "\f145"; }
.fa-times:before { content: "\f00d"; }
.fa-times-circle:before { content: "\f057"; }
.fa-times-circle-o:before { content: "\f05c"; }
.fa-times-rectangle:before { content: "\f2d3"; }
.fa-times-rectangle-o:before { content: "\f2d4"; }
.fa-tint:before { content: "\f043"; }
.fa-tipeee:before { content: "\f301"; }
.fa-toggle-down:before { content: "\f150"; }
.fa-toggle-left:before { content: "\f191"; }
.fa-toggle-off:before { content: "\f204"; }
.fa-toggle-on:before { content: "\f205"; }
.fa-toggle-right:before { content: "\f152"; }
.fa-toggle-up:before { content: "\f151"; }
.fa-trademark:before { content: "\f25c"; }
.fa-train:before { content: "\f238"; }
.fa-transgender:before { content: "\f224"; }
.fa-transgender-alt:before { content: "\f225"; }
.fa-trash:before { content: "\f1f8"; }
.fa-trash-o:before { content: "\f014"; }
.fa-tree:before { content: "\f1bb"; }
.fa-trello:before { content: "\f181"; }
.fa-tripadvisor:before { content: "\f262"; }
.fa-trophy:before { content: "\f091"; }
.fa-truck:before { content: "\f0d1"; }
.fa-try:before { content: "\f195"; }
.fa-tty:before { content: "\f1e4"; }
.fa-tumblr:before { content: "\f173"; }
.fa-tumblr-square:before { content: "\f174"; }
.fa-turkish-lira:before { content: "\f195"; }
.fa-tv:before { content: "\f26c"; }
.fa-twitch:before { content: "\f1e8"; }
.fa-twitter:before { content: "\f099"; }
.fa-twitter-square:before { content: "\f081"; }
.fa-umbrella:before { content: "\f0e9"; }
.fa-underline:before { content: "\f0cd"; }
.fa-undo:before { content: "\f0e2"; }
.fa-universal-access:before { content: "\f29a"; }
.fa-university:before { content: "\f19c"; }
.fa-unlink:before { content: "\f127"; }
.fa-unlock:before { content: "\f09c"; }
.fa-unlock-alt:before { content: "\f13e"; }
.fa-unsorted:before { content: "\f0dc"; }
.fa-unsplash:before { content: "\f325"; }
.fa-upload:before { content: "\f093"; }
.fa-usb:before { content: "\f287"; }
.fa-usd:before { content: "\f155"; }
.fa-user:before { content: "\f007"; }
.fa-user-circle:before { content: "\f2bd"; }
.fa-user-circle-o:before { content: "\f2be"; }
.fa-user-md:before { content: "\f0f0"; }
.fa-user-o:before { content: "\f2c0"; }
.fa-user-plus:before { content: "\f234"; }
.fa-user-secret:before { content: "\f21b"; }
.fa-user-times:before { content: "\f235"; }
.fa-users:before { content: "\f0c0"; }
.fa-utensil-spoon:before { content: "\f1b1"; }
.fa-utensils:before { content: "\f0f5"; }
.fa-vcard:before { content: "\f2bb"; }
.fa-vcard-o:before { content: "\f2bc"; }
.fa-venus:before { content: "\f221"; }
.fa-venus-double:before { content: "\f226"; }
.fa-venus-mars:before { content: "\f228"; }
.fa-viacoin:before { content: "\f237"; }
.fa-viadeo:before { content: "\f2a9"; }
.fa-viadeo-square:before { content: "\f2aa"; }
.fa-video:before { content: "\f03d"; }
.fa-video-camera:before { content: "\f03d"; }
.fa-vimeo:before { content: "\f27d"; }
.fa-vimeo-square:before { content: "\f194"; }
.fa-vimeo-v:before { content: "\f27d"; }
.fa-vine:before { content: "\f1ca"; }
.fa-vk:before { content: "\f189"; }
.fa-volume-control-phone:before { content: "\f2a0"; }
.fa-volume-down:before { content: "\f027"; }
.fa-volume-off:before { content: "\f026"; }
.fa-volume-up:before { content: "\f028"; }
.fa-warning:before { content: "\f071"; }
.fa-wechat:before { content: "\f1d7"; }
.fa-weibo:before { content: "\f18a"; }
.fa-weixin:before { content: "\f1d7"; }
.fa-whatsapp:before { content: "\f232"; }
.fa-wheelchair:before { content: "\f193"; }
.fa-wheelchair-alt:before { content: "\f29b"; }
.fa-wifi:before { content: "\f1eb"; }
.fa-wikidata:before { content: "\f31a"; }
.fa-wikipedia-w:before { content: "\f266"; }
.fa-window-close:before { content: "\f2d3"; }
.fa-window-close-o:before { content: "\f2d4"; }
.fa-window-maximize:before { content: "\f2d0"; }
.fa-window-minimize:before { content: "\f2d1"; }
.fa-window-restore:before { content: "\f2d2"; }
.fa-windows:before { content: "\f17a"; }
.fa-won:before { content: "\f159"; }
.fa-wordpress:before { content: "\f19a"; }
.fa-wpbeginner:before { content: "\f297"; }
.fa-wpexplorer:before { content: "\f2de"; }
.fa-wpforms:before { content: "\f298"; }
.fa-wrench:before { content: "\f0ad"; }
.fa-xing:before { content: "\f168"; }
.fa-xing-square:before { content: "\f169"; }
.fa-xmpp:before { content: "\f2f9"; }
.fa-y-combinator:before { content: "\f23b"; }
.fa-y-combinator-square:before { content: "\f1d4"; }
.fa-yahoo:before { content: "\f19e"; }
.fa-yc:before { content: "\f23b"; }
.fa-yc-square:before { content: "\f1d4"; }
.fa-yelp:before { content: "\f1e9"; }
.fa-yen:before { content: "\f157"; }
.fa-yoast:before { content: "\f2b1"; }
.fa-youtube:before { content: "\f167"; }
.fa-youtube-play:before { content: "\f16a"; }
.fa-youtube-square:before { content: "\f166"; }
.fa-zotero:before { content: "\f309"; }

/* Icons */
span[class^="icon"] {
	display: inline-block;
	height: 20px;
	text-align: center;
	text-indent: initial;
	width: 20px;

	&:before {
		display: inline-block;
		font-family: 'FontAwesomeSolid';
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
	}

	&.icon-search:before {
		content: "";
		background: url("@{bgs}") no-repeat;
		background-position: -60px 0;
		position: absolute;
		display: block;
		height: 40px;
		width: 40px;
		bottom: 0;
		right: 0;
	}
	&.icon-prev:before { content: "\f053"; font-size: 25px; }
	&.icon-next:before { content: "\f054"; font-size: 25px; }
	&.icon-plus:before { content: "\f067"; }
	&.icon-minus:before { content: "\f068"; }

	&.icon-people:before { content: "\f007"; }
	&.icon-delivery:before { content: "\f0d1"; }
	&.icon-payment:before { content: "\f09d"; }

	&.icon-minus, &.icon-plus {
		color: #fff;
		font-size: 10px;
		height: 16px;
		line-height: 16px;
		width: 16px;
	}

	&.icon-people, &.icon-delivery, &.icon-payment {
		background: none !important;
		clear: none !important;
		display: inline-block !important;
		font-size: 20px;
		height: 30px;
		line-height: 30px;
		margin: 0 5px 0 0 !important;
		width: 30px;
	}
}

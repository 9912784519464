//main: layout.less

#vitrine {
	.element-product-detail {
		color: @MineShaft;

		.product-main-image {
			border: 4px solid transparent;
			height: 450px;
			padding: 5px;
			.border-radius(5px);
		}

		.product-info {
			.product-name {
				text-transform: uppercase;
				min-height: 120px;
				line-height: 42px;
				font-size: 36px;
				color: @MineShaft;
				.font-medium;
			}

			.product-sku, .product-category {
				color: @MineShaft;
				font-size: 14px;
				line-height: 22px;
				.font-regular;
			}
		}

		.product-amount {
			display: none;
		}

		.product-rating  {
			color: @MineShaft;
			font-size: 14px;
			line-height: 22px;
		}

		.wrapper-product-price {
			.product-old-price {
				color: @MineShaft;
				visibility: visible;
				font-size: 14px;
				line-height: 22px;
			}

			.product-price, .product-new-price {
				text-transform: uppercase;
				visibility: visible;
				font-size: 14px;
				line-height: 30px;
				color: @MineShaft;
				.font-regular;

				.product-big-price {
					text-transform: uppercase;
					visibility: visible;
					font-size: 24px;
					line-height: 30px;
					.font-bold;
				}
			}

			.wrapper-btn-buy {
				a {
					opacity: 1;
					background: @AmaranthSecundary;

					span {
						&.btn-icon {
							display: none;
						}

						&.btn-text {
							text-transform: uppercase;
							transition: all .3s ease-in-out;
							visibility: visible;
							line-height: 30px;
							font-size: 24px;
							color: @white;
							.font-regular;
						}
					}
				}

				&:hover {
					a {
						opacity: .6;
					}
				}
			}
		}

		div[class^="box-"] {
			border: 2px solid @Amaranth;
			padding: 40px 20px;
			.border-radius(5px);

			.box-title {
				font-size: 16px;
				line-height: 24px;
				color: @MineShaft;
				.font-bold;
			}

			p {
				font-size: 14px;
				line-height: 24px;
				color: @OtherMineShaft;
			}

			.grid-cep {
				clear: both;
				display: inline;
				float: left;
				margin-bottom: 10px;
				width: 160px;

				input[type="text"] {
					border: 2px solid @Amaranth;
					background: transparent;
					line-height: 24px;
					padding: 10px 20px;
					.border-radius(5px);
				}
			}
		}
	}
}

.product-thumbnails {
	.product-thumbnails-list {
		display: table;
		margin: 0 auto;

		.product-thumbnails-item {
			display: inline;
			float: left;
			margin-right: 10px;
		}

		a, div {
			border: 2px solid transparent;

			&:hover, &.active {
				border-color: @Amaranth!important;
			}
		}
	}
}

.bx-wrapper.horizontal {
	.bx-controls-direction {
		a {
			top: 50%;
			transform: translate(0, -50%);
		}
	}
}

.element-product-detail {
	.wrapper-btn-actions {
		.btn {
			.btn-icon {
				display: none;
			}
		}
	}
}

.product-view-content {
	margin-top: 40px;
	color: @MineShaft;
}

.wrapper-table-kit, .wrapper-product-combination {
	.product-combination-list, .product-combination-list {
		.product-combination-item  {
			border: 2px solid transparent;
		}

		.product-combination-active {
			border: 2px solid @Amaranth;
			.border-radius(5px);
		}
	}
}

.zoomWindowContainer {
	.zoomWindow {
		left: 450px!important;
		.transform(scale(1.5));
	}
}

//main: layout.less

#header-main {
	background: @CodGray;
	color: @white;

	div[class^="header-"] {
		padding: 0;
	}
}

.element-logo {
	position: relative;
	display: inline-block!important;
	width: 100%;

	.logo {
		position: absolute;
		display: block!important;
		padding: 34px 30px;
		z-index: 5;
	}
}

.element-menu-top {
	display: block!important;
	text-transform: uppercase;
	line-height: 64px;
	font-size: 10px;
	margin: 18px 0px;
	display: block;
	width: 100%;

	.menu-top-list {
		display: flex;
		justify-content: space-between;

		li {
			text-align: center;
			position: relative;
			flex: 1;

			&::before {
				content: "";
				height: 10px;
				display: block;
				position: absolute;
				background: @gray;
				bottom: 9px;
				width: 1px;
				right: 0;
				top: 25px;
			}

			&:last-child {
				&::before {
					display: none;
				}
			}
		}
	}
}

.element-info {
	position: relative;
	width: 100%;
	padding: 23px 0;
	text-align: center;
    display: inline-block!important;

	.info-title {
		text-transform: uppercase;
		text-align: center;
		color: @AnotherAmarath;
		line-height: 30px;
		font-size: 12px;
		.font-regular;
	}

	.info-phone {
		position: relative;
		text-align: center;
		line-height: 24px;
		font-size: 12px;
		display: block;
		float: left;
		width: 50%;
		.fas;

		&::before {
			content: "\f095";
			margin-right: 5px;
		}

		&:after {
			content: "";
			height: 12px;
			display: block;
			position: absolute;
			background: @gray;
			bottom: 9px;
			width: 1px;
			right: 0;
			top: 9px;
		}

		&.info-whatsapp {
			.fab;

			&::before {
				content: "\f232";
				margin-right: 5px;
			}

			&:after {
				display: none;
			}
		}
	}

	.info-time, .info-label-whatsapp {
		display: none;
	}
}

.element-menu-category-header {
	padding-left: 192px;
	margin: 0;
	display: block!important;

	#menu-category-header {
		.menu-category-inner {
			.menu-category-list {
				justify-content: space-between;
				text-transform: uppercase;
				display: flex!important;
				font-size: 11px;
				line-height: 22px;
				.font-light;


				>li {
					padding: 18px 0 20px;
					flex: 1;
					float: unset;
					text-align: center;

					a {
						display: block!important;
						font-size: 11px!important;
						text-decoration: none!important;
						letter-spacing: 1px!important;

						&::before {
							content: "";
							margin: 0 auto 10px;
							display: block;
							background: url("@{bgs}");
						}

						&.legging {
							&::before {
								background-position: 0 0;
								width: 20px;
								height: 30px;
							}
						}

						&.shorts {
							&::before {
								background-position: 0 -30px;
								margin: 5px auto 15px;
								width: 30px;
								height: 20px;
							}
						}

						&.macacao {
							&::before {
								background-position: -20px 0;
								width: 20px;
								height: 30px;
							}
						}

						/*&.macaquinho {
							&::before {
								background-position: -40px 0;
								width: 20px;
								height: 30px;
							}
						}*/

						&.vestidos {
							&::before {
								background-position: -60px 0;
								width: 30px;
								height: 30px;
							}
						}

						&.tops {
							&::before {
								background-position: -30px -30px;
								margin: 5px auto 15px;
								width: 30px;
								height: 20px;
							}
						}

						&.bodys {
							&::before {
								background-position: -90px 0;
								width: 20px;
								height: 30px;
							}
						}

						/*&.conjuntos {
							&::before {
								background-position: -110px 0;
								width: 20px;
								height: 30px;
							}
						}*/

						&.blusinhas {
							&::before {
								background-position: -60px -300px;
								margin: 5px auto 15px;
								width: 30px;
								height: 20px;
							}
						}
					}

					> ul {
						top: 100%;
						width: 420px;
						max-width: 420px;
						background: @Purple;
						border-top: 5px solid @Amaranth;
						padding: 30px 40px;

						> li {
							width: 50%;
							text-transform: uppercase;
							text-align: left;
							float: left!important;

							&.title-submenu {
								width: 100%;
								color: @Amaranth;
								font-size: 24px;
								line-height: 30px;
								margin-bottom: 10px;
								.font-medium;
							}

							> a {
								font-size: 14px;
								line-height: 18px;
								.font-light;
							}

							&:hover {
								> ul {
									display: none!important;
								}
							}

							> ul {
								display:  none;
							}
						}
					}
				}
			}
		}
	}
}

.element-shopping-cart {
	display: inline-block!important;
	height: 100px;
    width: 100%;

	.link-pedidos, .link-shopping-cart {
		margin: 15px 0;
		position: relative;
		display: inline-block;
		text-transform: uppercase;
		text-decoration: none;
		color: @AnotherAmarath;
		font-size: 12px;
		float: left;
		width: 50%;

		&:hover {
			a {
				text-decoration: none!important;
			}
		}
	}

	.link-pedidos {
		display: inline;

		.pedidos {
			.list {
				display: block;

				&::before {
					content: "";
					background: url("@{bgs}");
					background-position: -90px -60px;
					display: block;
					margin: 5px auto;
					width: 30px;
					height: 20px;
				}
			}

			.meus-pedidos {
				margin-top: 13px;
				display: inline-block;
				text-align: center;
				width: 100%;
			}
		}

		&:after {
			content: "";
			height: 70px;
			display: block;
			position: absolute;
			background: @gray;
			width: 1px;
			top: 0;
			right: 0;
		}
	}

	.link-shopping-cart {

		.icon-shopping-cart {
			margin: 0 auto;
			display: block;
			width: 40px;
			height: 30px;

			&::before {
				content: "";
				background: url("@{bgs}");
				background-position: -90px -30px;
				display: inline-block;
				margin: 0 auto;
				width: 40px;
				height: 30px;
			}
		}

		.shopping-cart-title {
			margin-top: 13px;
			text-align: center;
		}
	}

	.shopping-cart-total-products-wrapper,
	.shopping-cart-total-price-wrapper {
		display: none!important;
	}
}

//main: layout.less

/* Containers */

.container-12 {
	margin-left: auto;
	margin-right: auto;
	max-width: 1150px;
	min-width: 960px;
	width: auto;
}

/* Grid >> Global */

.grid-num,
.grid-1,
.grid-2,
.grid-3,
.grid-4,
.grid-5,
.grid-6,
.grid-7,
.grid-8,
.grid-9,
.grid-10,
.grid-11,
.grid-12 {
	display:inline;
	float: left;
	position: relative;
	padding-left: 15px;
	padding-right: 15px;
	.box-sizing;
}

.push-1, .pull-1,
.push-2, .pull-2,
.push-3, .pull-3,
.push-4, .pull-4,
.push-5, .pull-5,
.push-6, .pull-6,
.push-7, .pull-7,
.push-8, .pull-8,
.push-9, .pull-9,
.push-10, .pull-10,
.push-11, .pull-11,
.push-12, .pull-12 {
	position:relative;
}

/* Grid >> Children (Alpha ~ First, Omega ~ Last) */

.alpha {
	margin-left: 0;
}

.omega {
	margin-right: 0;
}

/* Grid >> 12 Columns */

.container-12 .grid-num {
	width: 60px;
}

.container-12 .grid-1 {
	width:8.333%;
}

.container-12 .grid-2 {
	width:16.667%;
}

.container-12 .grid-3 {
	width:25.0%;
}

.container-12 .grid-4 {
	width:33.333%;
}

.container-12 .grid-5 {
	width:41.667%;
}

.container-12 .grid-6 {
	width:50.0%;
}

.container-12 .grid-7 {
	width:58.333%;
}

.container-12 .grid-8 {
	width:66.667%;
}

.container-12 .grid-9 {
	width:75.0%;
}

.container-12 .grid-10 {
	width:83.333%;
}

.container-12 .grid-11 {
	width:91.667%;
}

.container-12 .grid-12 {
	width:100%;
}

/* Clear Floated Elements */

/* http://sonspring.com/journal/clearing-floats */

.clear {
	clear: both;
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */

.clearfix:before,
.clearfix:after {
	content: '\0020';
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.clearfix:after {
  clear: both;
}

/*
	The following zoom:1 rule is specifically for IE6 + IE7.
	Move to separate stylesheet if invalid CSS is a problem.
*/

.clearfix {
  zoom: 1;
}

form {
	div[class^="grid"] {
		margin-left: 10px;
		margin-right: 10px;
		padding-left: 0;
		padding-right: 0;
	}
}

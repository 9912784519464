//main: layout.less

.catalogoprodutos {
	.wrapper-order-select {
		.label-block {
			display: none;
		}
	}
}


.wrapper-order-select {
    position: absolute;
    top: 165px;
    right: 0;
}

.element-filter-select-box {
	.element-filter {
		.select-box-container {
			.label-filter {
				text-transform: uppercase;
				font-size: 14px;
				line-height: 20px;
			}

			.select-box-placeholder {
				background: @MineShaft;
				color: @white;
				font-size: 20px;
				line-height: 22px;
				border: 2px solid transparent;
				padding: 10px 50px 10px 20px;
				text-transform: uppercase;
				.transition(all 350ms ease);
				.font-light;

				span {
					&::after {
						content: " + ";
						background-position: -60px -40px;
						display: inline-block;
						position: absolute;
						font-size: 35px;
						line-height: 46px;
						right: 10px;
						top: 2px;
						.font-medium;
					}
				}
			}

			.select-box-drop {
				border: 1px solid transparent;
				margin-top: 5px;
				padding: 5px 0;
				width: auto;
				.box-shadow(0px 5px 15px 0px rgba(0, 0, 0, 0.2));

				.select-box-list {
					li {
						a {
							text-transform: uppercase;
							text-decoration: none;
							padding: 3px 10px;
							display: block;
							.font-bold;
						}

						&:hover {
							a {
								color: @Amaranth;
							}
						}
					}
				}
			}
		}
	}
}

.element-selected-filters {
	border: 2px solid @black;

	.selected-filters-title {
		text-transform: uppercase;
		color: @black;
		.font-bold;
	}
}

.list-filter {
	display: table;
	width: 100%;

	li {
		display: inline;
		float: left;
		margin: 0 1% 20px;
		text-align: center;
		width: 23%;

		a {
			display: block;
			font-size: 12px;
			line-height: 15px;
		}
	}
}

//main: layout.less

.element-pagination {
    margin-bottom: 20px;
	.box-sizing;

	.pagination {
		color: @black;

		ul.pagination-list {
			margin: 0 auto 10px;

			li {
				margin: 0 5px !important;

				a, &.current {
					border: 0;
					background: transparent;
					font-size: 24px;
					line-height: 24px;
					min-width: 35px;
					overflow: hidden;
					position: relative;
					opacity: 1;
					.transition;
					.font-bold;

					&.prev, &.next {
						color: @Amaranth;

						.btn-text {
							display: none;
						}

						&.disabled {
							opacity: .6;
						}
					}
				}

				&.current {
					border-bottom: 3px solid @Amaranth;
				}

				&:hover a {
					border-bottom: 3px solid @Amaranth;
				}

				a {
					span[class^="icon"] {
						font-size: 24px;
						line-height: 24px;
						height: 24px;
						width: 24px;

						&:before {
							font-size: 24px;
							line-height: 24px;
							height: 24px;
							width: 24px;
						}
					}
				}
			}
		}

		.pagination-counter {
			display: none;
			margin: 20px;
		}
	}
}
